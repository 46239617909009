export default {
    url: 'https://admin.syrovarnya-tsk.online/',
    makeQuery(data, prefix) {
        if (data) {
            let query = [];
            for (let segment in data) {
                let field = prefix ? prefix + '[' + segment + ']' : segment;
                query.push(
                    typeof data[segment] === 'object'
                        ? this.makeQuery(data[segment], field)
                        : field + '=' + data[segment]
                );
            }

            return query.join('&');
        }
        return '';
    },
    makeFormData(data, formData, prefix) {
        if (!formData) {
            formData = new FormData();
        }
        for (let segment in data) {
            let field = prefix ? prefix + '[' + segment + ']' : segment;
            if (data[segment] instanceof File) {
                formData.append(field, data[segment]);
            } else if (typeof data[segment] === 'object') {
                this.makeFormData(data[segment], formData, field)
            } else {
                formData.append(field, data[segment]);
            }
        }
        return formData;
    },
    async get(url, data) {
        const requestUrl = this.url + url + '?' + this.makeQuery(data);
        return await fetch(requestUrl, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + localStorage.getItem('hash')
            }
        })
            .then(response => response.json())
            .then(data => {
                return data;
            });
    },
    async post(url, data) {
        return await fetch(this.url + url, {
            method: 'POST',
            headers: {
                "Authorization": "Token " + localStorage.getItem('hash')
            },
            body: this.makeFormData(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            });
    }

}