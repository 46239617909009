<template>
    <div class="navigation">
        <div class="menu">
            <div
                class="item"
                :class="isActive('dictionaries')"
                @click="changeActiveItem('dictionaries','/dictionaries/')"
            >
                <icon-base icon-name="dictionaries" width="30">
                    <icon-dictionaries></icon-dictionaries>
                </icon-base>
            </div>
            <div
                class="item"
                :class="isActive('catalog')"
                @click="changeActiveItem('catalog','/catalog/')"
            >
                <icon-base icon-name="catalog" width="30">
                    <icon-folder-open></icon-folder-open>
                </icon-base>
            </div>
            <div
                class="item"
                :class="isActive('clients')"
                @click="changeActiveItem('clients','/clients/')"
            >
                <icon-base icon-name="clients" width="30">
                    <icon-clients></icon-clients>
                </icon-base>
            </div>
            <div
                class="item"
                :class="isActive('managers')"
                @click="changeActiveItem('managers','/managers/')"
            >
                <icon-base icon-name="managers" width="30">
                    <icon-managers></icon-managers>
                </icon-base>
            </div>
            <div
                class="item"
                :class="isActive('orders')"
                @click="changeActiveItem('orders','/orders/')"
            >
                <icon-base icon-name="orders" width="30">
                    <icon-cart></icon-cart>
                </icon-base>
            </div>
            <div
                class="item"
                :class="isActive('sync')"
                @click="changeActiveItem('sync','/sync/')"
            >
                <icon-base icon-name="sync" width="30">
                    <icon-cloud></icon-cloud>
                </icon-base>
            </div>
        </div>
        <div class="auth">
            <router-link class="info" to="/manager-info">{{ authInfo }}</router-link>
        </div>
    </div>
</template>

<script>
import { AuthService } from "../services/auth.service";
import IconBase from "./icons/IconBase";
import IconClients from "./icons/IconClients";
import IconCart from "./icons/IconCart";
import IconManagers from "./icons/IconManagers";
import IconDictionaries from "./icons/IconDictionaries";
import IconFolderOpen from "./icons/IconFolderOpen";
import IconCloud from "./icons/IconCloud";

export default {
    name: "Navigation",
    components: {
        IconCloud,
        IconFolderOpen, IconDictionaries, IconManagers, IconCart, IconClients, IconBase
    },
    methods: {
        changeActiveItem(key, link) {
            this.active = key;
            this.$router.push(link);
        },
        isActive(key) {
            return {
                'is-active': this.active === key
            };
        }
    },
    computed: {
        authInfo() {
            return this.authService.user
                ? this.authService.user['fio'][0]
                : '';
        }
    },
    data() {
        return {
            authService: AuthService,
            active: null
        }
    }
}
</script>
