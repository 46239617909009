<template>
    <div class="content">
        <div class="title">Главная</div>
        <div class="list">
            <router-link to="/dictionaries/" class="item">
                <div class="title">Словари</div>
                <div class="description">Типы цен, список складов, статусы, хар-ки</div>
            </router-link>
            <router-link to="/catalog/" class="item">
                <div class="title">Каталог</div>
                <div class="description">Категории, товары, цены, остатки</div>
            </router-link>
            <router-link to="/orders/" class="item">
                <div class="title">Заказы</div>
                <div class="description">Список заказов</div>
            </router-link>
            <router-link to="/clients/" class="item">
                <div class="title">Клиенты</div>
                <div class="description">Список клиентов</div>
            </router-link>
            <router-link to="/managers/" class="item">
                <div class="title">Менеджеры</div>
                <div class="description">Список менеджеров</div>
            </router-link>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Home',
    components: {},
}
</script>
