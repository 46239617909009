import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { AuthService } from "../services/auth.service";

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            auth: true,
            navigation: true
        },
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/catalog',
        name: 'Catalog',
        meta: {
            auth: true,
            navigation: true
        },
        component: () => import('../views/Catalog.vue')
    },
    {
        path: '/dictionaries',
        name: 'Dictionaries List',
        meta: {
            auth: true,
            navigation: true
        },
        component: () => import('../views/Dictionaries.vue')
    },
    {
        path: '/dictionaries/:dictionary',
        name: 'Dictionaries',
        meta: {
            auth: true,
            navigation: true
        },
        component: () => import('../views/Dictionaries.vue')
    },
    {
        path: '/orders',
        name: 'Orders',
        meta: {
            auth: true,
            navigation: true
        },
        component: () => import('../views/Orders.vue')
    },
    {
        path: '/clients',
        name: 'Clients',
        meta: {
            auth: true,
            navigation: true
        },
        component: () => import('../views/Clients.vue')
    },
    {
        path: '/managers',
        name: 'Managers',
        meta: {
            auth: true,
            navigation: true
        },
        component: () => import('../views/Managers.vue')
    },
    {
        path: '/manager-info',
        name: 'Manager Info',
        meta: {
            auth: true,
            navigation: true
        },
        component: () => import('../views/ManagerInfo.vue')
    },
    {
        path: '/sync',
        name: 'Sync',
        meta: {
            auth: true,
            navigation: true
        },
        component: () => import('../views/Sync.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const service = AuthService;
    const requireAuth = to.matched.some(record => record.meta['auth']);

    if (requireAuth) {
        if (!service.checked && service.getHash()) {
            service.checkSession().then(response => {
                next(!response ? '/login' : null);
            })
        } else {
            next(!service.checkSession() ? '/login' : null);
        }
    } else {
        next();
    }

});

export default router
