import { reactive } from "vue";
import HttpService from "./http.service";

export const AuthService = reactive({
    user: null,
    hash: null,
    checked: false,
    login(email, password) {
        return HttpService.post('managers/login', {
            email: email,
            password: password
        }).then(response => {
            if (response.success) {
                this.user = response.user;
                this.hash = response.hash;
                localStorage.setItem('hash', this.hash);
            }

            return this.isAuthorized();
        });
    },
    checkSession() {
        const hash = localStorage.getItem('hash');
        if (hash && this.checked === false) {
            this.checked = true;
            return HttpService.post('managers/checkSession', {
                hash: hash
            }).then(response => {
                if (response.success) {
                    this.user = response.user;
                    this.hash = response.hash;
                }

                return this.isAuthorized();
            });
        }

        return this.isAuthorized();
    },
    changeStore(storeId) {
        if (this.isAuthorized()) {
            return HttpService.post('managers/changeStore', {
                'store_id': storeId,
                'id': this.user.id
            }).then(response => {
                if (response.success === true) {
                    this.user['store_id'] = storeId;
                }
            })
        }
    },
    changePrice(priceId) {
        if (this.isAuthorized()) {
            return HttpService.post('managers/changePrice', {
                'price_id': priceId,
                'id': this.user.id
            }).then(response => {
                if (response.success === true) {
                    this.user['price_id'] = priceId;
                }
            })
        }
    },
    isAuthorized() {
        return !!this.user;
    },
    getHash(){
        return localStorage.getItem('hash');
    },
    getStoreId() {
        return this.isAuthorized()
            ? this.user['store_id']
            : null;
    },
    getPriceId() {
        return this.isAuthorized()
            ? this.user['price_id']
            : null;
    }
});