<template>
    <div class="wrapper">
        <navigation v-if="showNavigation"></navigation>
        <router-view/>
    </div>
</template>
<script>
import Navigation from "./components/Navigation";

export default {
    computed: {
        showNavigation() {
            return this.$route.meta['navigation'];
        }
    },
    components: {Navigation}
}
</script>